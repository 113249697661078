import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from './events/Store'

import Http from './events/Http'
import Config from './events/Config'

import Common from './common'

import Home from './components/Home'
import Lotteries from './components/Lotteries'
import LiveGames from './components/LiveGames'
import VideoPoker from './components/VideoPoker'
import Slots from './components/Slots'
import Crash from './components/Crash'
import Jackpots from './components/Jackpots'
import TableGames from './components/TableGames'

import Game from './components/Game'
import Search from './components/Search'

/** Information routes **/
import About from './components/informations/About'
import KYCPolicies from './components/informations/KYCPolicies'
import CookiePolicy from './components/informations/CookiePolicy'
import DisputeResolution from './components/informations/DisputeResolution'
import IntegrityGames from './components/informations/IntegrityGames'
import PayoutsBonuses from './components/informations/PayoutsBonuses'
import PrivacyPolicy from './components/informations/PrivacyPolicy'
import ResponsiblePlay from './components/informations/ResponsiblePlay'
import SelfRestraint from './components/informations/SelfRestraint'
import TermsConditions from './components/informations/TermsConditions'
import Partners from './components/informations/Partners'
/** Information routes **/

/** Account routes **/
import AccountProfile from './components/account/Profile'
import AccountBalance from './components/account/Balance'
import AccountMessages from './components/account/Messages'
import AccountRestraint from './components/account/Restraint'
import AccountVerification from './components/account/Verification'
import AccountExperience from './components/account/Experience'
/** Account routes **/

/** Cashier routes **/
import CashierPromotionsHistory from './components/cashier/PromotionsHistory'
import CashierPaymentHistory from './components/cashier/PaymentHistory'
import CashierDeposit from './components/cashier/Deposit'
import CashierDepositType from './components/cashier/DepositType'
import CashierWithdraw from './components/cashier/Withdraw'
import CashierWithdrawType from './components/cashier/WithdrawType'
/** Cashier routes **/

/** Tournaments routes **/
//import Tournaments from './components/tournaments/Tournaments'
//import Tournament from './components/tournaments/Tournament'
/** Tournaments routes **/

/** Experience routes **/
import Experiences from './components/experience/Experiences'
import Experience from './components/experience/Experience'
import ExperienceShop from './components/experience/Shop'
/** Experience routes **/

/** Promo routes **/
import PromoMain from './components/promo/Main'
import PromoCashback from './components/promo/Cashback'
import PromoPersonal from './components/promo/Personal'
import PromoStarting from './components/promo/Starting'
import PromoStatus from './components/promo/Status'
/** Promo routes **/

/** Auth routes **/
import Registration from './components/auth/Registration'
import Restore from './components/auth/Restore'
import RestoreAction from './components/auth/RestoreAction'
/** Auth routes **/

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: '',
    linkExactActiveClass: '',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/lotteries',
            name: 'Lotteries',
            component: Lotteries,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/live-games',
            name: 'LiveGames',
            component: LiveGames,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/video-poker',
            name: 'VideoPoker',
            component: VideoPoker,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/slots',
            name: 'Slots',
            component: Slots,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/crash',
            name: 'Crash',
            component: Crash,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/jackpots',
            name: 'Jackpots',
            component: Jackpots,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/table-games',
            name: 'TableGames',
            component: TableGames,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/game/:mode/:slug',
            name: 'Game',
            component: Game,
            props: true,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/search',
            name: 'Search',
            component: Search,
            meta: {
                auth: 'all'
            }
        },

        /** Information routes **/
        {
            path: '/about',
            name: 'About',
            component: About,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/aml-and-kyc-policies',
            name: 'KYCPolicies',
            component: KYCPolicies,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/cookie-policy',
            name: 'CookiePolicy',
            component: CookiePolicy,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/dispute-resolution',
            name: 'DisputeResolution',
            component: DisputeResolution,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/integrity-of-games',
            name: 'IntegrityGames',
            component: IntegrityGames,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/payouts-and-bonuses',
            name: 'PayoutsBonuses',
            component: PayoutsBonuses,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/privacy-policy',
            name: 'PrivacyPolicy',
            component: PrivacyPolicy,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/responsible-play',
            name: 'ResponsiblePlay',
            component: ResponsiblePlay,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/self-restraint',
            name: 'SelfRestraint',
            component: SelfRestraint,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/terms-and-conditions',
            name: 'TermsConditions',
            component: TermsConditions,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/partners',
            name: 'Partners',
            component: Partners,
            meta: {
                auth: 'all'
            }
        },
        /** Information routes **/

        /** Account routes **/
        {
            path: '/account/profile',
            name: 'AccountProfile',
            component: AccountProfile,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/balance',
            name: 'AccountBalance',
            component: AccountBalance,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/messages',
            name: 'AccountMessages',
            component: AccountMessages,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/restraint',
            name: 'AccountRestraint',
            component: AccountRestraint,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/verification',
            name: 'AccountVerification',
            component: AccountVerification,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/account/experience',
            name: 'AccountExperience',
            component: AccountExperience,
            meta: {
                auth: 'auth'
            }
        },
        /** Account routes **/

        /** Cashier routes **/
        {
            path: '/cashier/promotions/history',
            name: 'CashierPromotionsHistory',
            component: CashierPromotionsHistory,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/cashier/deposit',
            name: 'CashierDeposit',
            component: CashierDeposit,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/cashier/history',
            name: 'CashierPaymentHistory',
            component: CashierPaymentHistory,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/cashier/deposit/:paysys_id/:slug',
            name: 'CashierDepositType',
            component: CashierDepositType,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/cashier/withdraw',
            name: 'CashierWithdraw',
            component: CashierWithdraw,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/cashier/withdraw/:paysys_id/:slug',
            name: 'CashierWithdrawType',
            component: CashierWithdrawType,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        /** Cashier routes **/

        /** Tournaments routes **/
        /*{
            path: '/tournaments',
            name: 'Tournaments',
            component: Tournaments,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/tournament/:id',
            name: 'Tournament',
            component: Tournament,
            props: true,
            meta: {
                auth: 'all'
            }
        },*/
        /** Tournaments routes **/

        /** Experience routes **/
        {
            path: '/experience',
            name: 'Experiences',
            component: Experiences,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/experience-:id',
            name: 'Experience',
            component: Experience,
            props: true,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/experience/shop',
            name: 'ExperienceShop',
            component: ExperienceShop,
            meta: {
                auth: 'all'
            }
        },
        /** Experience routes **/

        /** Promo routes **/
        {
            path: '/promo',
            name: 'PromoMain',
            component: PromoMain,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/promo/cashback',
            name: 'PromoCashback',
            component: PromoCashback,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/promo/personal',
            name: 'PromoPersonal',
            component: PromoPersonal,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promo/starting',
            name: 'PromoStarting',
            component: PromoStarting,
            meta: {
                auth: 'all'
            }
        },
        {
            path: '/promo/status',
            name: 'PromoStatus',
            component: PromoStatus,
            meta: {
                auth: 'all'
            }
        },
        /** Promo routes **/


        /** Auth routes **/
        {
            path: '/registration',
            name: 'Registration',
            component: Registration,
            meta: {
                auth: 'guest'
            }
        },
        {
            path: '/restore',
            name: 'Restore',
            component: Restore,
            meta: {
                auth: 'guest'
            }
        },
        {
            path: '/restore/:token',
            name: 'RestoreAction',
            component: RestoreAction,
            props: true,
            meta: {
                auth: 'guest'
            }
        }
        /** Auth routes **/
    ]
})

router.beforeEach(async (to, from, next) => {

    var user = Store.state.user

    if(!('auth' in user)) {
        await Http.post(`${Config.ApplicationAPI}/auth/check`)
        .then((response) => {
            response = response.data
            switch (response.status) {
                case 'error':
                    console.log('check auth error')
                    break
                case 'success':
                
                    document.body.classList.remove('overflow-hidden')
                    Store.commit('setLoaded', true)
                    Store.commit('setUser', response.user)
                    Store.commit('setLang', response.language.lang)
                    Store.commit('setLanguages', response.language.languages)
                    Store.commit('setTranslation', response.translation)

                    localStorage.setItem('lang', response.language.lang)
                    Http.defaults.headers.common.Lang = response.language.lang

                    break
                default:
                    console.log('Unknown error')
            }
        }).catch((error) => {
            document.body.classList.remove('overflow-hidden')
            Store.commit('setLoaded', true)
        })

        user = Store.state.user


        /** Load Chat **/
        /*window.chaportConfig = {
            appId: '647a159f3b5769d7a90ce341',
            language: {
                use: 'ru'
            },
            appearance: {
                windowColor: '#e9417d',
                position: ['right', 20, 20],
                textStatuses: true
            }
        }

        if (user.auth) {
            window.chaportConfig.visitor = {
                name: user.data.personal.first_name && user.data.personal.last_name ? `${user.data.personal.first_name} ${user.data.personal.last_name}` : user.data.username,
                email: user.data.email,
                custom: {
                    status: user.data.rating.name,
                    balance: user.data.balance
                }
            }
        }

        if (window.innerWidth <= 1300) {
            window.chaportConfig.appearance.position = ['right', 20, 65]
        }

        if (!window.chaport) {
            var v3 = window.chaport = {}
            v3._q = []
            v3._l = {}

            v3.q = function(){
                v3._q.push(arguments)
            }

            v3.on = function(e,fn) {
                if(!v3._l[e])
                {
                    v3._l[e] = []
                    v3._l[e].push(fn)
                }
            }

            var s = document.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://app.chaport.com/javascripts/insert.js'

            var ss = document.getElementsByTagName('script')[0]
            ss.parentNode.insertBefore(s,ss)
        }*/
    }

    if((!('auth' in user) || !user.auth) && to.meta.auth == 'auth' && from.meta.auth != to.meta.auth) {
        router.push({name: 'Home'})
    } else if((('auth' in user) && user.auth) && to.meta.auth == 'guest' && from.meta.auth != to.meta.auth) {
        router.push({name: 'Home'})
    }

    next()

})


export default router