import Vue from 'vue'
import router from './router'
import http from './events/Http'
import store from './events/Store'
import config from './events/Config'

import App from './App.vue'

import VueTippy, { TippyComponent } from 'vue-tippy'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Moment from 'moment-timezone'
import 'moment/locale/ru'
import VueCountdownTimer from 'vuejs-countdown-timer'
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'
//import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false

Vue.prototype.$config = config
Vue.prototype.$axios = http
Vue.prototype.$sprintf = require('sprintf-js').sprintf

Vue.use(VueTippy)
Vue.use(VueToast, {
    position: 'top-right',
    duration: 30000
})
Vue.use(VueReCaptcha, {
  siteKey: config.RecaptchaV3.token
})
Vue.use(VueCountdownTimer)
Vue.use(VueMask)
Vue.use(VueMeta)
Vue.use(VueCookies, {
    expires: '1y'
})
/*Vue.use(VueAnalytics, {
    id: 'G-NX42V1PBKZ',
    //disableScriptLoader: true,
    autoTracking: {
        exception: true,
        exceptionLogs: false
    },
    router
})*/


Moment.tz.setDefault(config.Timezone)
Moment.locale('ru')
Vue.prototype.$moment = Moment

Vue.component('tippy', TippyComponent)
Vue.component('v-select', vSelect)


Vue.mixin({
  methods: {
    setUser (data) {
        store.commit('setUser', data)
    },
    setLang (value) {
        store.commit('setLang', value)
    },
    setLanguages (value) {
        store.commit('setLanguages', value)
    },
    setTranslation (value) {
        store.commit('setTranslation', value)
    },
    setLoaded (data) {
        store.commit('setLoaded', data)
    },
    setLoadedGames (data) {
        store.commit('setLoadedGames', data)
    },
    async recaptchaV3() {
        await this.$recaptchaLoaded()
        return await this.$recaptcha(config.RecaptchaV3.action)
    },
    getPromoStatuses () {
        this.$axios.post('/users/status/items')
        .then((response) => {
              response = response.data
              switch (response.status) {
                  case 'error':
                      break
                  case 'success':
                      store.commit('setPromoStatuses', response.data)
                      break
                  default:
                  //Error
              }
        })
    },
    getPromoTemplates () {
        this.$axios.post('/promotions/templates/items')
        .then((response) => {
            response = response.data
            switch (response.status) {
                case 'error':
                    break
                case 'success':
                    store.commit('setPromoTemplates', response.data)
                    break
                default:
                //Error
            }
        })
    },
    openPopup(popup_id) {
        document.querySelector('#' + popup_id).classList.add('popup_show')
        document.querySelector('body').classList.add('popup-show')
        document.querySelector('html').classList.add('popup-show')
        document.querySelector('html').classList.add('lock')
    },
    closePopup(e) {
        let element = 'target' in e ? e.target : e

        for (var i = 10; i >= 0; i--) {
            if(element.classList.contains('popup')) {
                break
            } else {
                element = element.parentElement
            }
        }

        element.classList.remove('popup_show')
        document.body.classList.remove('popup-show')
        document.documentElement.classList.remove('popup-show')
        document.documentElement.classList.remove('lock')
    }
  }
})

new Vue({
    el: '#app',
    router,
    store,
    computed: {
        auth () {
            return {
                auth: store.state.user.auth,
                status: store.state.user.data.status
            }
        }
    },
    watch: {
        auth (user, old) {
            var route = this.$router.currentRoute

            if(route.meta.auth == 'auth' && user.status != 'active') {
                this.$axios.defaults.headers.common.Authorization = null
                router.push({name: 'Home'})
            }
          
            if (route.meta.auth == 'auth' && !user.auth) {
                this.$axios.defaults.headers.common.Authorization = null
                router.push({name: 'Home'})
            } else if (route.meta.auth == 'guest' && user.auth) {
                router.push({name: 'Home'})
            }
        }
    },
    render: (h) => h(App)
})
