export default {
  ApplicationNAME: 'Tree-X',
  ApplicationAPI: `https://${window.location.hostname}/api/v1`,
  ApplicationGamesAPI: `https://${window.location.hostname}/games-app/api/v1`,
  ApplicationURL: `https://${window.location.hostname}`,
  Langs: ['ru', 'en'],
  RecaptchaV3: {
    token: '6Le9DQUoAAAAAC-kTrdU6zlFPZa56JU3xj21UAwM',
    action: 'casino'
  },
  RecaptchaV2: {
    token: '6Le9DQUoAAAAAC7yWYzCoxVn_Ftx2do_sYYfs0nH'
  },
  Timezone: 'Europe/Moscow'
}
