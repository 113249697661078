<template>
	<div class="banner">

		<swiper
            v-bind="swiper_options"
            :thumbs="{ swiper: thumbsSwiper }"
            class="banner__slider swiper">
            <swiper-slide v-for="item in $store.state.banners">
                <div class="banner__slide swiper-slide">
	                <picture>
	                    <source :srcset="`${$config.ApplicationURL}/banners/${item.image_big}`" />
	                    <img src="../../assets/img/transparent.png" width="1440" height="460" alt="" />
	                </picture>
	            </div>
            </swiper-slide>

            <div class="swiper-pagination"></div>
        </swiper>

        <div class="banner__container">
        	<swiper
	            v-bind="swiper_options_thumb"
	            @swiper="setThumbsSwiper"
	            class="banner-mini__slider swiper">
	            <swiper-slide v-for="item in $store.state.banners" class="banner-mini__slide">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="312" height="11" viewBox="0 0 312 11">
                        <path
                            id="SVGID_1_1"
                            d="M0,9.8L8.9,0L12,1.2L3.1,11L0,9.8z M12,9.8L20.9,0L24,1.2L15.1,11L12,9.8z M24,9.8L32.9,0L36,1.2L27.1,11 L24,9.8z M36,9.8L44.9,0L48,1.2L39.1,11L36,9.8z M48,9.8L56.9,0L60,1.2L51.1,11L48,9.8z M60,9.8L68.9,0L72,1.2L63.1,11L60,9.8z M72,9.8L80.9,0L84,1.2L75.1,11L72,9.8z M84,9.8L92.9,0L96,1.2L87.1,11L84,9.8z M96,9.8l8.9-9.8l3.1,1.2L99.1,11L96,9.8z M108,9.8 l8.9-9.8l3.1,1.2l-8.9,9.8L108,9.8z M120,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L120,9.8z M132,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L132,9.8z M144,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L144,9.8z M156,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L156,9.8z M168,9.8l8.9-9.8l3.1,1.2l-8.9,9.8 L168,9.8z M180,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L180,9.8z M192,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L192,9.8z M204,9.8l8.9-9.8l3.1,1.2 l-8.9,9.8L204,9.8z M216,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L216,9.8z M228,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L228,9.8z M240,9.8l8.9-9.8 l3.1,1.2l-8.9,9.8L240,9.8z M252,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L252,9.8z M264,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L264,9.8z M276,9.8 l8.9-9.8l3.1,1.2l-8.9,9.8L276,9.8z M288,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L288,9.8z M300,9.8l8.9-9.8l3.1,1.2l-8.9,9.8L300,9.8z"
                        ></path>
                        <clipPath id="SVGID_2_">
                            <use xlink:href="#SVGID_1_1" style="width: 100%;"></use>
                        </clipPath>
                        <line class="st0" x1="0" y1="5.5" x2="312px" y2="5.5"></line>
                        <line class="pink" x1="0" y1="5.5" x2="312px" y2="5.5"></line>
                    </svg>
                    <picture>
                        <source :srcset="`${$config.ApplicationURL}/banners/${item.image_small}`" />
                        <img src="../../assets/img/transparent.png" width="143" height="50" alt="" />
                    </picture>
	            </swiper-slide>
	        </swiper>
	    </div>
	</div>
</template>

<script>
import { Navigation, Pagination, Autoplay, Thumbs } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

SwiperCore.use([Navigation, Pagination, Autoplay, Thumbs])

export default {
	components: {
        Swiper,
        SwiperSlide,
    },
    data () {
        return {
            swiper_options: {
                autoplay: {
                	delay: 15000, 
                	disableOnInteraction: false
                },
	            parallax: true,
	            observer: true,
	            observeParents: true,
	            slidesPerView: 1,
	            spaceBetween: 0,
	            autoHeight: true,
	            speed: 700,
	            pagination: {
	            	el: '.swiper-pagination', 
	            	clickable: true
	            }
            },
            swiper_options_thumb: {
	            parallax: true,
	            observer: true,
	            observeParents: true,
	            slidesPerView: 8,
	            spaceBetween: 20,
            },
            thumbsSwiper: null
        }
    },
    methods: {
        getItems () {
            this.$axios.post(`/banners/items`)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$store.state.banners = response.data
                        this.setLoaded(true)
                        break
                    default:
                        this.$toast.error(this.$store.state.translation.default.unknown_error)
                }
            }).catch((error) => {
                let response = error.response.data

                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        setThumbsSwiper (swiper) {
	        this.thumbsSwiper = swiper
	    }
    },
    beforeMount() {
    	this.setLoaded(false)

        if(!this.$store.state.banners.length) {
            this.getItems()
        }
    }
}
</script>