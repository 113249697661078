<template>
    <footer class="footer">
        <div class="footer__container">
            <div class="footer__top lang_footer_select">
                <v-select 
                    v-if="1==2"
                    @input="changeLang"
                    v-model="lang"
                    :options="languages"
                    label="name"
                    placeholder="Русский">
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                         {{ $store.state.translation.default.nothing_found }}
                      </template>
                    </template>
                </v-select>

                <nav class="footer__navigation">
                    <div class="footer__line">
                        <router-link :to="{name: 'About'}" class="footer__link">{{ $store.state.translation.common.footer.about }}</router-link>
                        <router-link :to="{name: 'TermsConditions'}" class="footer__link">{{ $store.state.translation.common.footer.terms_conditions }}</router-link>
                        <router-link :to="{name: 'ResponsiblePlay'}" class="footer__link">{{ $store.state.translation.common.footer.responsible_play }}</router-link>
                        <router-link :to="{name: 'CookiePolicy'}" class="footer__link">{{ $store.state.translation.common.footer.cookie_policy }}</router-link>
                        <router-link :to="{name: 'PrivacyPolicy'}" class="footer__link">{{ $store.state.translation.common.footer.privacy_policy }}</router-link>
                    </div>
                    <div class="footer__line">
                        <router-link :to="{name: 'SelfRestraint'}" class="footer__link">{{ $store.state.translation.common.footer.restraint }}</router-link>
                        <router-link :to="{name: 'DisputeResolution'}" class="footer__link">{{ $store.state.translation.common.footer.dispute }}</router-link>
                        <router-link :to="{name: 'KYCPolicies'}" class="footer__link">{{ $store.state.translation.common.footer.kyc }}</router-link>
                        <router-link :to="{name: 'IntegrityGames'}" class="footer__link">{{ $store.state.translation.common.footer.integrity_games }}</router-link>
                        <router-link :to="{name: 'PayoutsBonuses'}" class="footer__link">{{ $store.state.translation.common.footer.payouts_bonuses }}</router-link>
                        <router-link :to="{name: 'Partners'}" class="footer__link">{{ $store.state.translation.common.footer.partners }}</router-link>
                    </div>
                </nav>
            </div>
            <div class="footer__main">
                <div class="footer__row">
                    <div class="footer__col">
                        <a content="Red Tiger" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/redtiger.svg" width="100" alt="Red Tiger" />
                            </picture>
                        </a>
                        <a content="Green Tube" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/greentube.svg" width="100" alt="Green Tube" />
                            </picture>
                        </a>
                        <a content="Evolution Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/evolutiongaming.svg" width="100" alt="Evolution Gaming" />
                            </picture>
                        </a>
                        <a content="Amigo Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/amigogaming.svg" width="100" alt="Amigo Gaming" />
                            </picture>
                        </a>
                        <a content="Relax Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/relaxgaming.svg" width="65" alt="Relax Gaming" />
                            </picture>
                        </a>
                        <a content="Amatic" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/amatic.svg" width="70" alt="Amatic" />
                            </picture>
                        </a>
                        <a content="Endorphina" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/endorphina.svg" width="70" alt="Endorphina" />
                            </picture>
                        </a>
                        <a content="Nolimit City" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/nolimitcity.svg" width="75" alt="Nolimit City" />
                            </picture>
                        </a>
                        <a content="Igrosoft" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/igrosoft.svg" width="65" alt="Igrosoft" />
                            </picture>
                        </a>
                        <a content="Belatra" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/belatra.svg" width="75" alt="Belatra" />
                            </picture>
                        </a>
                        <a content="Netent" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/netent.svg" width="75" alt="Netent" />
                            </picture>
                        </a>
                        <a content="Thunderkick" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/thunderkick.svg" width="75" alt="Thunderkick" />
                            </picture>
                        </a>
                        <a content="Playson" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/playson.svg" width="100" alt="Playson" />
                            </picture>
                        </a>
                        <a content="Egt Interactive" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/egtinteractive.svg" width="100" alt="Egt Interactive" />
                            </picture>
                        </a>
                        <a content="Pragmatic Play" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/pragmaticplay.svg" width="80" style="margin-top: -7px" alt="Pragmatic Play" />
                            </picture>
                        </a>
                        <a content="Microgaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/microgaming.svg" width="65" alt="Microgaming" />
                            </picture>
                        </a>
                        <a content="Ezugi" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/ezugi.svg" width="65" alt="Ezugi" />
                            </picture>
                        </a>
                        <a content="Evoplay" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/evoplay.svg" width="100" alt="Evoplay" />
                            </picture>
                        </a>
                        <a content="Spinomenal" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/spinomenal.svg" width="110" alt="Spinomenal" />
                            </picture>
                        </a>
                        <a content="RTG Slots" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/rtgslots.svg" width="65" alt="RTG Slots" />
                            </picture>
                        </a>
                        <a content="Booming Games" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/boominggames.svg" width="65" alt="Booming Games" />
                            </picture>
                        </a>
                        <a content="Habanero" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/habanero.svg" width="110" alt="Habanero" />
                            </picture>
                        </a>
                        <a content="Tom Horn" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/tomhorn.svg" width="90" alt="Tom Horn" />
                            </picture>
                        </a>
                        <a content="HOGaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/hogaming.svg" width="90" style="margin-top: 10px" alt="HOGaming" />
                            </picture>
                        </a>
                        <a content="KA Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/kagaming.svg" width="70" alt="KA Gaming" />
                            </picture>
                        </a>
                        <a content="Xpro Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/xprogaming.svg" width="80" alt="Xpro Gaming" />
                            </picture>
                        </a>
                        <a content="Justplay" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/justplay.svg" width="110" alt="Justplay" />
                            </picture>
                        </a>
                        <a content="Super Spade Games" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/superspadegames.svg" width="70" alt="Super Spade Games" />
                            </picture>
                        </a>
                        <a content="Saucify" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/saucify.svg" width="100" alt="Saucify" />
                            </picture>
                        </a>
                        <a content="G.Games" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/ggames.svg" width="80" alt="G.Games" />
                            </picture>
                        </a>
                        <a content="CT Interactive" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/casinotechnologyinteractive.svg" width="120" alt="CT Interactive" />
                            </picture>
                        </a>
                        <a content="Caleta" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/caleta.svg" width="80" alt="Caleta" />
                            </picture>
                        </a>
                        <a content="Ainsworth Game Technology" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/ainsworthgametechnology.svg" width="120" alt="Ainsworth Game Technology" />
                            </picture>
                        </a>
                        <a content="BGaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/bgaming.svg" width="120" alt="BGaming" />
                            </picture>
                        </a>
                        <a content="BF Games" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/bfgames.svg" width="70" alt="BF Games" />
                            </picture>
                        </a>
                        <a content="ReelNRG" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/reelnrg.svg" width="100" alt="ReelNRG" />
                            </picture>
                        </a>
                        <a content="Wazdan" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/wazdan.svg" width="120" alt="Wazdan" />
                            </picture>
                        </a>
                        <a content="DLV" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/dlv.svg" width="75" alt="DLV" />
                            </picture>
                        </a>
                        <a content="Eurasian Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/eurasiangaming.svg" width="90" alt="Eurasian Gaming" />
                            </picture>
                        </a>
                        <a content="Triple Cherry" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/triplecherry.svg" width="65" style="margin-top: -5px" alt="Triple Cherry" />
                            </picture>
                        </a>
                        <a content="Spribe" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/spribe.svg" width="65" alt="Spribe" />
                            </picture>
                        </a>
                        <a content="iSoftBet" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/isoftbet.svg" width="100" alt="iSoftBet" />
                            </picture>
                        </a>
                        <a content="Golden Race" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/goldenrace.svg" width="120" alt="Golden Race" />
                            </picture>
                        </a>
                        <a content="ZeusPlay" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/zeusplay.svg" width="100" alt="ZeusPlay" />
                            </picture>
                        </a>
                        <a content="Mascot Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/mascotgaming.svg" width="100" alt="Mascot Gaming" />
                            </picture>
                        </a>
                        <a content="Vivo Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/vivogaming.svg" width="100" alt="Vivo Gaming" />
                            </picture>
                        </a>
                        <a content="КабиHollywoodTVнет" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/hollywoodtv.svg" width="120" alt="HollywoodTV" />
                            </picture>
                        </a>
                        <a content="Red Rake" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/redrake.svg" width="80" alt="Red Rake" />
                            </picture>
                        </a>
                        <a content="Just for the Win" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/justforthewin.svg" width="80" alt="Just for the Win" />
                            </picture>
                        </a>
                        <a content="Foxium" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/foxium.svg" width="100" alt="Foxium" />
                            </picture>
                        </a>
                        <a content="Skilrock" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/skilrock.svg" width="100" alt="Skilrock" />
                            </picture>
                        </a>
                        <a content="Revolver Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/revolvergaming.svg" width="100" alt="Revolver Gaming" />
                            </picture>
                        </a>
                        <a content="Big Time Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/bigtimegaming.svg" width="75" alt="Big Time Gaming" />
                            </picture>
                        </a>
                        <a content="Retro Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/retrogaming.svg" width="100" alt="Retro Gaming" />
                            </picture>
                        </a>
                        <a content="Fantasma" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/fantasma.svg" width="90" alt="Fantasma" />
                            </picture>
                        </a>
                        <a content="NetGaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/netgaming.svg" width="75" alt="NetGaming" />
                            </picture>
                        </a>
                        <a content="Spadegaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/spadegaming.svg" width="75" alt="Spadegaming" />
                            </picture>
                        </a>
                        <a content="Rhino Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/rhinogaming.svg" width="75" alt="Rhino Gaming" />
                            </picture>
                        </a>
                        <a content="True Lab" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/truelab.svg" width="90" alt="True Lab" />
                            </picture>
                        </a>
                        <a content="Leap Gaming" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/leapgaming.svg" width="75" alt="Leap Gaming" />
                            </picture>
                        </a>
                        <a content="Skywind Group" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/skywindgroup.svg" width="100" alt="Skywind Group" />
                            </picture>
                        </a>
                        <a content="NetGame Entertainment" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/netgameentertainment.svg" width="75" alt="NetGame Entertainment" />
                            </picture>
                        </a>
                        <a content="Gamebeat" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/gamebeat.svg" width="100" alt="Gamebeat" />
                            </picture>
                        </a>
                        <a content="Superlotto" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/superlotto.svg" width="100" alt="Superlotto" />
                            </picture>
                        </a>
                        <a content="Mplay" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/mplay.svg" width="80" alt="Mplay" />
                            </picture>
                        </a>
                        <a content="OneTouch" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/onetouch.svg" width="100" alt="OneTouch" />
                            </picture>
                        </a>
                        <a content="Onlyplay" v-tippy="{arrow : true, arrowType: 'round'}">
                            <picture>
                                <img src="../../assets/img/providers/onlyplay.svg" width="100" alt="Onlyplay" />
                            </picture>
                        </a>
                    </div>
                </div>
            </div>
            <div class="footer__copyright">
                © Tree-X {{new Date().getFullYear()}}. All rights reserved.
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        data() {
            return {
                lang: null,
                languages: [
                    {
                        name: 'Русский',
                        key: 'ru'
                    },
                    {
                        name: 'English',
                        key: 'en'
                    }
                ]
            }
        },
        methods: {
            changeLang(lang) {
                this.$axios.post('/users/lang/change', JSON.stringify({
                    lang: lang.key
                }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":

                            this.setUser(response.user)

                            this.setLang(response.language.lang)
                            this.setLanguages(response.language.languages)
                            this.setTranslation(response.translation)

                            localStorage.setItem('lang', response.language.lang)
                            this.$axios.defaults.headers.common.Lang = response.language.lang

                            break
                        default:
                            this.$toast.error(this.$store.state.translation.axios.unknown_error)
                    }
                }).catch((error) => {
                    this.loader_button_lang = false
                    let response = error.response.data
                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
        },
        beforeMount () {
            this.lang = this.languages.find((item) => item.key == this.$store.state.lang)
        }
    }
</script>
