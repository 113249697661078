<template>
    <div class="wrapper">
        <header-main></header-main>

        <main class="page page--pt">
            <section class="content content--mb content--mb-stat">
                <div class="content__container">
                    
                    <account-personal-info></account-personal-info>
                    
                    <div class="personal personal--center">
                        <h2 class="personal__title form-title">{{ $store.state.translation.account.balance.balance }}</h2>
                        <div class="personal__list personal-list">
                            <div class="personal-list__item">
                                <div class="personal-list__main">
                                    <div class="personal-list__title">{{ $store.state.translation.account.balance.balance }}:</div>
                                    <div class="personal-list__cost">
                                        <template v-if="'auth' in $store.state.user">
                                            {{ $store.state.user.data.balance.toFixed(2) }}
                                        </template>
                                        <span>{{ $store.state.user.data.merchant.currency }}</span>
                                    </div>
                                </div>
                                <router-link :to="{name: 'CashierDeposit'}" class="personal-list__link">
                                    {{ $store.state.translation.account.balance.to_cashier }}
                                </router-link>
                            </div>
                            <!--
                            <div class="personal-list__item">
                                <div class="personal-list__main">
                                    <div class="personal-list__title">{{ $store.state.translation.account.balance.bonus_balance }}:</div>
                                    <div class="personal-list__cost">
                                        <template v-if="'auth' in $store.state.user">
                                            {{ $store.state.user.data.balance_bonus.toFixed(2) }}
                                        </template>
                                        <span>{{ $store.state.user.data.merchant.currency }}</span>
                                    </div>
                                </div>
                                <router-link :to="{name: 'CashierPromotionsHistory'}" class="personal-list__link">
                                    {{ $store.state.translation.account.balance.see_bonuses }}
                                </router-link>
                            </div>-->

                            <div class="personal-list__item" :class="{'personal-list__item--disable': $store.state.user.data.rating.id == 1 || !$store.state.user.data.balance_bonus }">
                                <div class="personal-list__row">
                                    <div class="personal-list__main">
                                        <div class="personal-list__title">{{ $store.state.translation.account.balance.bonus_balance }}:</div>
                                        <div class="personal-list__cost">
                                            {{ $store.state.user.data.balance_bonus.toFixed(2) }}
                                            <span>{{ $store.state.user.data.merchant.currency }}</span>
                                        </div>
                                    </div>
                                    <div style="cursor: pointer;" class="personal-list__link" @click="bonusToReal" :class="{'disabled-button': loader_button}">{{ $store.state.translation.account.balance.bonus_to_real }}</div>
                                </div>
                            </div>

                            <div class="personal-list__item personal__item__cachback" :class="{'personal-list__item--disable': !claimable }">
                                <div class="personal-list__row">
                                    <div class="personal-list__main">
                                        <div class="personal-list__title">{{ $store.state.translation.account.balance.cashback }}:</div>
                                        <div class="personal-list__cost">
                                            {{ cachback.toFixed(2) }}
                                            <span>{{ $store.state.user.data.merchant.currency }}</span>
                                        </div>
                                    </div>
                                    <div class="personal-list__link" @click="claim" :class="{'disabled-button': loader_button}">{{ $store.state.translation.account.balance.get_cashback }}</div>
                                </div>
                                <div class="personal-list__reason">
                                    {{ $sprintf($store.state.translation.account.balance.min_cashback, $store.state.user.data.merchant.min_cashback, $store.state.user.data.merchant.currency) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

        <footer-main></footer-main>
    </div>
</template>

<script>
    export default {
        metaInfo () {
            return {
                title: this.$store.state.translation.account.balance.meta.title,
                meta: [
                    {
                        name: 'title', 
                        content: this.$store.state.translation.account.balance.meta.title
                    },
                    {
                        name: 'keywords', 
                        content: this.$store.state.translation.account.balance.meta.keywords
                    },
                    {
                        name: 'description', 
                        content: this.$store.state.translation.account.balance.meta.description
                    }
                ]
            }
        },
        data() {
            return {
                loader_button: false,
                cachback: 0,
                claimable: false
            }
        },
        methods: {
            getCachback () {
                this.$axios.post('/cashback/amount')
                .then((response) => {
                    this.setLoaded(true)
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.cachback = response.amount
                            this.claimable = response.claimable

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            claim () {
                this.loader_button = true

                this.$axios.post('/cashback/claim')
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.$toast.success(response.message)

                            this.getCachback()

                            this.claimable = false

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            },
            bonusToReal () {
                this.loader_button = true

                this.$axios.post('/users/balance/bonus_to_real')
                .then((response) => {
                    this.loader_button = false
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':

                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            
                            break
                        default:
                            this.$toast.error(this.$store.state.translation.default.unknown_error)
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if(response && 'status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            }
        },
        beforeMount() {
            this.setLoaded(false)
            this.getCachback()
        }
    }
</script>